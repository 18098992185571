import React from "react";
import "../../index.css";
import { useSpring, animated } from "react-spring";
import SEO from "../components/SEO";

//Next, define your spring

const Projects = () => {
  const props = useSpring({ opacity: 1, from: { opacity: 0 } });
  return (
    <div className="">
      <SEO />
      <script src="https://gist.github.com/topaztee/b5d4b59335c43b53d8aee7a2f842ad02.js" />
      <div className="project-items">
        <animated.div style={props}>
          <h2 className="heading mt-10 mb-6">Projects</h2>
          <div className="flex-grid">
            <div className="col">
              <ul>
                <h3>2022</h3>
                <li>
                  <a href="http://www.whoowns.app/?utm_source=topaztee.com">️ 🤖 who owns slack bot</a>{" "}
                  <span className="badge">In progress</span>
                </li>
                <h3>2020</h3>
                <li>
                  <a href="http://mightyslides.com/">✏️ mightyslides</a>{" "}
                  interactive presentation tool
                  {/*<span className="badge">In progress</span>*/}
                </li>
                <li>
                  <a href="https://github.com/topaztee/react-skeleton-app">
                    🏁 react app
                  </a>{" "}
                  starter app
                </li>
                <li>
                  <a href="https://codepen.io/topaztee/pen/abdJJPg">
                    🔖 bookmarklet{" "}
                  </a>
                  to view any github repo in codesandbox
                </li>
              </ul>
              <ul>
                <h3>2019</h3>
                <li>
                  <a
                    href="https://chrome.google.com/webstore/detail/trello-custom-progress-ba/afbaaokfpieehjeffeceajdopnlempob?hl=en"
                    className="href"
                  >
                    🌐 chrome extension{" "}
                  </a>
                  thats gives Trello's progress bar more flare.{" "}
                  <a
                    href="https://github.com/topaztee/chrome-extension-trello-custom-progress-bar"
                    className="href"
                  >
                    <i className="fa fa-github fa-1x" aria-hidden="true" />
                  </a>
                </li>
                <h3>2018</h3>
                <li>
                  <a href="https://codepen.io/topaztee/full/rdwpaj">
                    📍 New and Proposed Building Developments within the City of
                    Melbourne
                  </a>
                  <a href="https://codepen.io/topaztee/pen/rdwpaj">
                    <i className="fa codepen fa-1x" aria-hidden="true" />
                  </a>
                  <br />I used Mapbox to create a 3D representation of all
                  buildings in Melbourne under construction
                </li>
              </ul>
              <ul>
                {" "}
                <h3>2015</h3>
                <li>
                  📱 an Iphone app that helps you learn and practice the{" "}
                  <a
                    href="https://en.wikipedia.org/wiki/Mnemonic_major_system"
                    className="href"
                  >
                    Mnemonic major system
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </animated.div>
      </div>
    </div>
  );
};

export default Projects;
